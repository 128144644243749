import React from 'react'

import Layout from 'src/components/layout/index'

import SEO from 'src/components/seo/index'

import styles from './index.module.scss'


const IndexPage = () => {
	return (
		<Layout className={styles.top}>
			<SEO lang="ja" title="" path="/" description="作家・栗原明志(クリハラ アカシ)のウェブサイトです。2019年9月に作品社から『浮きよばなれ〜島国の彼岸へと漕ぎ出す日本文学芸術論』を上梓。" />
		</Layout>
	)
}

export default IndexPage
